<template>
    <div id="DataDetails">
        <p class="returnButton"><router-link :to="{ name: 'DataTable' }"><span>Return to Database Table</span></router-link></p>
        <h1>{{ theCurrentEntry.NameofCollection }}</h1>
        <h2> {{ theCurrentEntry.NameofInstitution }}</h2>
        <p>Description forthcoming.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
           magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
           commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
           nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
           anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
           incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
           laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
           esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
           qui officia deserunt mollit anim id est laborum.</p>
        <div class="split">
            <div>
                <h3>Tags: Themes and Topics</h3>
                <div v-if="this.theCurrentEntry.ActivismTopics" class="topicsList">
                    <span v-for="topic in topics2" :key="topic" class="topics">{{ topic }}</span>
                </div>
            </div>
            <div>
                <h3>Collection Details</h3>
                <table class="tg">
                    <tbody>
                    <tr>
                        <td class="tg-0pky">Decades of Collection</td>
                        <td class="tg-0pky">{{ theCurrentEntry.DecadeofCollectionSeries }}</td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">Data Formats</td>
                        <td class="tg-0pky">{{ theCurrentEntry.FormatofCollectionSeries }}</td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">Collection Size (Physical)</td>
                        <td class="tg-0pky">{{ theCurrentEntry.SizeofCollection }}<span v-if="!theCurrentEntry.SizeofCollection">N/A</span></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">Collection Size (Digital)</td>
                        <td class="tg-0pky">{{ theCurrentEntry.SizeofCollectionDigital }} <span v-if="!theCurrentEntry.SizeofCollectionDigital">N/A</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import DataTable from "@/pages/DataTable.vue";
    
    export default {
        name: "DataDetails",
        computed: {
            DataTable() {
                return DataTable
            }
        },
        data() {
            return {
                theCurrentEntry: [],
                data: [],
                topics2: []
            }
        },
        created() {
            const request = new XMLHttpRequest();
            request.open("GET", "/pjs-testdata-1.json", false);
            request.send(null)
            this.data = (JSON.parse(request.responseText));
            
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].Id === this.$route.params.table_id) {
                    this.theCurrentEntry = this.data[i]
                }
            }
        },
        mounted() {
            this.topics2 = this.theCurrentEntry.ActivismTopics.split(',')
        },
    }
</script>

<style scoped lang="scss">
    #DataDetails {
        text-align: left;
        color: black;
        width: 80%;
        margin: 0 auto;
        max-width: 1440px;
    }
    
    h1 {
        margin: 0;
        font-size: 42px;
    }
    
    h2 {
        color: #505050;
        margin-top: 0;
        font-size: 32px;
    }
    
    h3 {
        font-size: 26px;
    }
    
    p {
        font-size: 20px;
    }
    
    .topicsList {
        display: flex;
        grid-gap: 10px;
    }
    
    .topics {
        background-color: #FFDD00;
        border-radius: 30px;
        padding: 8px 12px;
        font-weight: normal;
        letter-spacing: 1px;
    }
    
    .tg {
        border-collapse: collapse;
        border-spacing: 0;
        border-radius: 20px;
        padding-bottom: 20px;
    }
    
    .tg td {
        overflow: hidden;
        padding: 10px 15px;
        word-break: normal;
        border: 1px solid black;
    }
    
    .tg th {
        border: 1px solid black;
        overflow: hidden;
        padding: 10px 15px;
        word-break: normal;
    }
    
    .returnButton {
        text-align: right;
        margin-bottom: 0;
        a {
            text-decoration: none;
        }
        span {
            color: #6d6d6d;
            padding: 4px 8px;
            border-radius: 5px;
            &:hover {
                color: #0C2747;
            }
        }
    }
    
    .split {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        grid-gap: 40px;
    }
</style>