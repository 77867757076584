<template>
    <div id="Footer">
        <div class="footerContainer">
            <div id="footer">
                <div class="logoContainer">
                    <img class="logos" src="https://mouseion.aadhum.org/wl/?id=9CH28rq1wPt8uo6Gdy0alDVMTeYcoqxk&fmode=open" alt="Mellon Foundation Logo">
                </div>
                <div class="textContainer">
                    <h3 class="centralTitle">Project STAND</h3>
                    <p class="centralText">Student Activism Now Documented</p>
                    <a href="https://standarchives.com/" target="_blank" class="centralLink">[ Visit our Main Site ]</a>
                </div>
                <div class="textContainer">
                    <p class="textInfo">Site Design by <a href="https://awwsmith.com/" target="_blank">Andrew W.
                                                                                                       Smith</a></p>
                    <p class="textInfo">Hosted by the <a href="https://irlhumanities.org/" target="_blank">Immersive
                                                                                                           Realities
                                                                                                           Labs for the
                                                                                                           Humanities</a>
                    </p>
                    <p class="textInfo">Copyright © 2024 All Rights Reserved</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyFooter"
    }
</script>

<style scoped lang="scss">
    a {
        color: #FFDD00;
        
        &:hover {
            color: #ECEFF1;
        }
    }
    
    .footerContainer {
        min-height: 200px;
        width: 100%;
        background-image: url("https://awikhigan.irlh.org/gen/wl/?id=rXpWiXwtf9syOjyQrEHGkPafKxQCK0Jw");
        background-size: cover;
        background-repeat: no-repeat;
        align-items: center;
        display: flex;
        margin-top: 80px;
        padding: 20px 0;
    }
    
    #footer {
        width: 80%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;

        grid-gap: 40px;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 1140px) {
            flex-direction: column;
        }
    }
    
    .logos {
        width: 250px;
        margin: 0 auto;
    }
    
    .centralTitle {
        color: #FFDD00;
        margin: 0;
    }
    
    .centralText {
        color: #FFDD00;
        margin: 0 0 10px 0;
    }
    
    .centralLink {
        color: white;
        font-size: 20px;
        text-decoration: none;
        
        &:hover {
            color: #aae8ff;
            text-decoration: underline;
        }
    }
    
    .textInfo {
        color: #ebebeb;
        text-align: right;
        font-size: 16px;
        @media only screen and (max-width: 800px) {
            text-align: center;
        }
    }
</style>