import {createRouter, createWebHistory} from 'vue-router'
import DataTable from '../pages/DataTable.vue'
import DataDetails from "@/pages/DataDetails.vue";
import HomeView from "@/pages/HomeView.vue";
import DashboardOverview from "@/pages/DashboardOverview.vue";
import AboutView from "@/pages/AboutView.vue";

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView
    },
    {
        path: '/dashboard',
        name: 'DashboardOverview',
        component: DashboardOverview
    },
    {
        path: '/datatable',
        name: 'DataTable',
        component: DataTable
    },
    {
        path: '/datatable/:table_id',
        name: 'DataDetails',
        component: DataDetails
    },
    {
        path: '/about',
        name: 'About',
        component: AboutView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
