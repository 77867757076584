<template>
    <div style="max-width: 800px; min-width: 400px; flex-grow: 1;">
        <canvas id="topContributors"></canvas>
    </div>
</template>

<script>
    import Chart from 'chart.js/auto';
    
    export default {
        name: "TopContributors",
        data() {
            return {
                data: {
                    labels: [
                        'Stanford University Archives',
                        'Barnard Archives and Special Collections',
                        'Purdue University',
                        'The Ohio State University',
                        'University of Virginia'
                    ],
                    datasets: [{
                        label: 'Collections',
                        data: [87, 20, 19, 18, 18],
                        borderWidth: 2
                    }]
                }
            }
        },
        mounted() {
            new Chart(
                document.getElementById('topContributors'),
                {
                    type: 'bar',
                    data: this.data,
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        },
                        indexAxis: 'y',
                        plugins: {
                            title: {
                                display: true,
                                text: 'Top 5 Contributors',
                                font: {
                                    size: '32',
                                    family: 'Berlin'
                                }
                            },
                            subtitle: {
                                display: true,
                                text: 'by Total Collections Contributed',
                                font: {
                                    size: '20',
                                    family: 'Berlin'
                                }
                            },
                            legend: {
                                font: {
                                    display: true,
                                    size: '50px',
                                    family: 'Berlin'
                                }
                            }
                        }
                    }
                }
            )
        }
    }
</script>

<style scoped lang="scss">

</style>