<template>
    <div class="homeContainer">
        <div id="Home">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
               magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
               commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
               nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
               anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
               incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
               laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
               esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
               qui officia deserunt mollit anim id est laborum.</p>
            <div class="split">
                <div class="teaserBox dashboards">
                    <img src="https://awikhigan.irlh.org/gen/wl/?id=EO3ilTxqMWBQxMziLvM2zAawVxrH4uc5" alt="">
                    <h2>Search the Database</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                       magna aliqua.</p>
                    <p class="button"><router-link :to=" { name: 'DataTable' }" class="navLink"><span>Search</span></router-link></p>
                </div>
                <div class="teaserBox database">
                    <img src="https://awikhigan.irlh.org/gen/wl/?id=W2S1NOoBbOrKTnwTiS3oni8qr2lO1dZf" alt="">
                    <h2>Explore our Data Visualizations</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                       magna aliqua.</p>
                    <p class="button"><router-link :to=" { name: 'DashboardOverview'}" class="navLink"><span>Visualizations</span></router-link></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HomeView"
    }
</script>

<style scoped lang="scss">
    .homeContainer {
        width: 80%;
        margin: 0 auto;
        max-width: 1440px;
    }
    
    p {
        text-align: left;
        line-height: 1.3;
        font-family: "Times New Roman", Serif;
        font-size: 24px;
    }
    
    h2 {
        text-align: left;
    }
    
    .split {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 120px;
        padding-top: 40px;
        @media only screen and (max-width: 800px) {
            display: flex;
            flex-direction: column;
        }
    }
    
    .teaserBox {
        display: flex;
        flex-direction: column;
        
        h2 {
            margin: 18px 0;
        }
        
        img {
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
            max-width: 100%;
            height: 350px;
            object-fit: cover;
            object-position: left;
        }
        
        p {
            margin: 0;
        }
    }
    
    .button {
        text-align: center;
        padding: 14px 0 0 0;
        
        span {
            background-color: #FFDD00;
            padding: 10px 10px;
            font-size: 20px;
            margin: 0 auto;
            font-family: Berlin, Avenir, Helvetica, Arial, sans-serif;
            
            &:hover {
                box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
            }
        }
    }
    
    .navLink {
        color: #0C2747;
        text-decoration: none;
    }
</style>