<template>
    <div class="aboutContainer">
        <div class="titleBack">
            <h1>About this Website</h1>
        </div>
        <div id="About">
            <p>Project STAND's database serves as a beacon, illuminating archival and historical collections that chronicle the unyielding spirit of student activism. This digital repository, a cornerstone of the 80+ member consortium, amplifies the voices of marginalized communities, offering a platform for the narratives that have often been relegated to the margins of historical discourse. With a focus on Black, Queer, Indigenous, Latinx, Women's Rights, and other historically disenfranchised perspectives and all their intersectionality, the database will point to a mosaic of stories that have shaped social movements.</p>
            <p>While not exhaustive, Project STAND's database is a resource, updated annually to incorporate newly discovered and processed collections; highlighting photos, digital records, flyers, posters, oral histories, correspondence, ephemera, etc. Currently in its beta phase, this tool aims to assist in increasing accessibility and discoverability of these critical materials, fostering a deeper understanding of the pivotal role played by marginalized communities in the ongoing struggle for justice and equality across the United States. As it evolves, the database will serve as a catalyst for further research, education, and dialogue, ensuring that these vital histories are preserved, and celebrated!</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AboutView"
    }
</script>

<style scoped lang="scss">
    .aboutContainer {
        width: 80%;
        margin: 0 auto;
        max-width: 1440px;
    }
    p {
        text-align: left;
        line-height: 1.3;
        font-family: "Times New Roman", Serif;
        font-size: 24px;
    }
</style>