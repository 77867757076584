<template>
    <div class="databaseContainer">
<!--        <div class="titleBack">-->
<!--            <h1>Collections Data Table</h1>-->
<!--        </div>-->
        <div id="Database">
            <div id="myTable"></div>
        </div>
    </div>
</template>

<script>
    import {Grid, h} from "gridjs";
    import "gridjs/dist/theme/mermaid.css";
    import router from "@/router/router";
    
    export default {
        name: 'DataTable',
        data() {
            return {
                data: [],
                cols: [
                    {
                        id: 'NameofCollection',
                        name: 'Name of Collection'
                    },
                    {
                        id: 'NameofInstitution',
                        name: 'Name of Institution'
                    },
                    {
                        id: 'Topic',
                        name: 'Population'
                    },
                    {
                        id: 'Id',
                        name: 'Id',
                        hidden: true
                    },
                    {
                        id: 'Data',
                        name: 'Data',
                        hidden: true
                    },
                    {
                        id: 'DecadeofCollectionSeries',
                        name: 'Decade of Collection'
                    },
                    {
                        id: 'State',
                        name: 'State'
                    },
                    // {
                    //     id: 'AccessReadiness',
                    //     name: 'Access Readiness'
                    // },
                    {
                        id: 'ActivismTopics',
                        name: 'Tags'
                    },
                    {
                        name: 'Learn More',
                        formatter: (cell, row) => {
                            const myId = `${row.cells[3].data}`
                            console.log(myId)
                            return h('button', {
                                style: 'background-color: #FFDD00; border: none; border-radius: 0; padding: 10px; font-family: Berlin;',
                                onClick: () => router.push({ name: 'DataDetails', params: { table_id: `${row.cells[3].data}` }  })
                            }, 'More Info')
                        }
                    }
                ]
            }
        },
        created() {
            const request = new XMLHttpRequest();
            request.open("GET", "pjs-testdata-1.json", false);
            request.send(null)
            this.data = (JSON.parse(request.responseText));
        },
        mounted() {
            new Grid({
                columns: this.cols,
                data: this.data,
                search: true,
                sort: true,
                pagination: {limit: 15}
            }).render(document.getElementById("myTable"))
            
        }
    }
</script>

<style scoped lang="scss">
    #Database {
        width: 80%;
        margin: 0 auto;
    }
    
    #myTable {
        padding: 20px 0;
    }
    
    .myButton {
        background-color: #FFDD00;
    }
    
    td > button {
        background-color: #FFDD00;
    }
    
    .titleBack {
        padding: 40px;
    }
</style>