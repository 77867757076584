<template>
    <div id="MyHeader">
        <div class="headerContainer">
            <img src="https://awikhigan.irlh.org/gen/wl/?id=g5sxviE7lYrGDbz5ydcWDnDW1KZDDnLB" alt="" class="logo"/>
            <div class="menuContainer">
                <div class="secondary">
                    <span class="navTitle">PJS Collections Data <span class="navBeta">[ Beta ]</span></span>
                </div>
                <div class="menu">
                    <router-link :to=" { name: 'HomeView' }" class="navLink">Home</router-link>
                    <router-link :to=" { name: 'DataTable' }" class="navLink">Search</router-link>
                    <router-link :to=" { name: 'DashboardOverview'}" class="navLink">Visualizations</router-link>
                    <router-link :to=" { name: 'About' }" class="navLink">About</router-link>
                </div>
            </div>
        </div>
        <div class="mobileMenuContainer">
            <div class="secondary">
                <span class="navTitle">PJS Collections Data <span class="navBeta">[ Beta ]</span></span>
            </div>
            <img class="menuIcon" src="https://awikhigan.irlh.org/gen/wl/?id=6dxQteWXgslq439qvGQS7OqRPwsOTTM7" alt="Menu Icon">
            <div class="mobileLinks">
                <router-link :to=" { name: 'HomeView' }" class="navLinkMobile">Home</router-link>
                <router-link :to=" { name: 'DataTable' }" class="navLinkMobile">Search</router-link>
                <router-link :to=" { name: 'DashboardOverview'}" class="navLinkMobile">Visualizations</router-link>
                <router-link :to=" { name: 'About' }" class="navLinkMobile">About</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyHeader"
    }
</script>

<style scoped lang="scss">
    #MyHeader {
        background-color: #0C2747;
    }
    
    .headerContainer {
        display: flex;
        width: 80%;
        max-width: 1440px;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        grid-gap: 20px;
    }
    
    .logo {
        width: 50%;
        max-width: 125px;
        padding: 20px;
    }
    
    .menu {
        display: flex;
        grid-gap: 40px;
        font-size: 24px;
        padding-top: 10px;
        
        .navLink {
            color: white;
        }
        
    }
    
    .secondary {
        display: flex;
        justify-content: flex-end;
    }
    
    .navTitle {
        color: #FFDD00;
        text-align: right;
        font-size: 26px;
    }
    
    .navBeta {
        color: #b2f8ff;
        font-size: 22px;
    }
    
    .navLink {
        text-decoration: none;
        
        &:hover {
            color: #FFDD00;
        }
    }
    
    .mobileMenuContainer {
        display: none;
    }
    
    .mobileLinks {
        display: none;
        position: absolute;
        flex-direction: column;
        z-index: 1;
        min-width: 200px;
        background-color: #fff;
        margin: 0 auto;
        width: 100vw;
        height: 100vh;
        box-shadow: none;
        // right: -20px;
        top: 80px;
        left: auto;
        padding-top: 20px;
    }
    
    .mobileMenuContainer:hover .mobileLinks {
        display: flex;
    }
    
    .mobileLinks a {
        color: black;
        font-size: 18px;
        font-family: trade-gothic-next, sans-serif;
        font-weight: 400;
        font-style: normal;
        padding: 12px 16px;
        text-align: center;
    }
    
    .mobileLinks a:hover {
        background-color: #d8d8d8;
    }
    
    @media only screen and (max-width: 800px) {
        .logo {
            display: none;
        }
        .navTitle {
            text-align: left;
        }
        .menuContainer {
            display: none;
        }
        .mobileMenuContainer {
            display: flex;
            position: relative;
            grid-gap: 40px;
            width: 80%;
            margin: 0 auto;
            justify-content: center;
            padding: 30px 0;
        }
        .menuIcon {
            height: 40px;
        }
    }
</style>